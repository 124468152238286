<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop='name' label='联系人'>
        <el-input v-model="form.data.name" placeholder="请输入" clearable></el-input>
      </el-form-item>
      <el-form-item prop='account' label='联系电话'>
        <el-input v-model="form.data.account" placeholder="请输入" maxlength="11" clearable :disabled="isChange"></el-input>
      </el-form-item> 
      <el-form-item label="所在地区" prop="area_arr">
        <el-cascader
          :popper-append-to-body="false"
          popper-class="areaCascaderWrap"
          style="width:100%;"
          v-model="form.data.area_arr"
          :options="areaArr"
          :props="{ checkStrictly: true }"
          :disabled="isChange">
        </el-cascader>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  export default {
    name: 'AreaEdit',
    props: ['areaArr'],
    data() {
      return {
        token: '',
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            name: '',
            account: '',
            province: '',
            city: '',
            area: '',
            area_arr: [],
          },
          rules: {
            name: [{ required: true, message:'必填项', trigger: 'change' }],
            account: [{ required: true, message: '必填项', trigger: 'change' }],
            area_arr: [{ required: true, message: '必填项', trigger: 'change' }],
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        this.isChange = true;
        this.$nextTick(() => {
          common.deepClone(this.form.data, row)
          if(row.area == 0) {
            this.form.data.area_arr = [row.province,row.city]
          } else {
            this.form.data.area_arr = [row.province,row.city,row.area]
          }
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let _area_arr = this.form.data.area_arr;
            this.form.data.province = _area_arr[0];
            this.form.data.city = _area_arr[1];
            this.form.data.area = _area_arr[2] || 0;
            let _params = { ...this.form.data}
            delete _params.area_arr
            let apiUrl = !this.isChange ? '/admin/flex_area/add' : '/admin/flex_area/edit'
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>

<style >
.areaCascaderWrap .el-cascader-menu:nth-of-type(1) .el-radio {
  display: none !important;
}
</style>
