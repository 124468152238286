<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="联系人" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入" @keydown.enter.native="onSearch"></el-input>
        </el-form-item>
        <el-form-item label="合作区域" prop="area_arr">
          <el-cascader
            :popper-append-to-body="false"
            popper-class="areaCascaderWrap"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            filterable
            clearable
            @change="onSearch">
        </el-cascader>
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="table.params.status" clearable @change="onSearch">
            <el-option label="全部" :value="0"></el-option>
            <el-option label="开通" :value="1"></el-option>
            <el-option label="关闭" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="showEdit">添加</el-button>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province="{row}">
        <span>{{row.province_str}}{{row.city_str}}{{row.area_str}}</span>
      </template>
      <template v-slot:status="{row}">
        <span>{{row.status == 1 ? '开通' : '关闭'}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
        <el-button type="text" @click="remove(row)">{{row.status == 1 ? '关闭' : '开通'}}</el-button>
      </template>
    </VTable>

    <edit ref="edit" :areaArr="areaArr" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import { mixinTable } from '@/mixins/table.js';
export default {
  name: 'AreaList',
  components: {
    VTable,
    Edit,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "name", label: "联系人", hidden: false },
        { name: "account", label: "电话", showTooltip: true, hidden: false },
        { name: "province", label: "开通地区", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "200", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          province: '',
          city: '',
          area: '',
          area_arr: [],
          status: 0,
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _area_arr = this.table.params.area_arr;
      this.table.params.province = _area_arr[0];
      this.table.params.city = _area_arr[1];
      this.table.params.area = _area_arr[2];
      let _params = { ...this.table.params }
      delete _params.area_arr;
      this.$http.get('/admin/flex_area/list', { params: _params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 开通、关闭
    remove(row) {
      let str = row.status == 1 ? '关闭' : '开通'
      this.$confirm(`请确认${str}操作？`, "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.optionsFn(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    optionsFn(rowid) {
      this.$http.post('/admin/flex_area/operation', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
  }
}
</script>
<style >
.areaCascaderWrap .el-cascader-menu:nth-of-type(1) .el-radio {
  display: none !important;
}
</style>